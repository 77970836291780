/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@mixin desktop {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin ultra-narrow-desktop {
    @media (min-width: 1024px)
        and (max-width: 1160px) {
        @content;
    }
}

@mixin narrow-desktop {
    @media (min-width: 1024px)
        and (max-width: 1280px) {
        @content;
    }
}

@mixin wide-desktop {
    @media (min-width: 1280px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 811px)
        and (max-width: 1023px)
        and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin tablet-portrait {
    @media (min-width: 811px)
        and (max-width: 1023px)
        and (-webkit-min-device-pixel-ratio: 1)
        and (orientation: portrait) {
        @content;
    }
}

@mixin tablet-landscape {
    @media(min-width: 811px)
        and (max-width: 1023px)
        and (-webkit-min-device-pixel-ratio: 1)
        and (orientation: landscape) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 1023px) {
        @content;
    }
}

@mixin standalone {
    /* stylelint-disable-next-line media-feature-name-no-unknown */
    @media all and (display-mode: standalone) {
        @content;
    }
}

@mixin hoverable {
    @media (hover: hover) and (pointer: fine) { 
        @content;
    }
}

// Custom Breakpoints
@mixin desktop-xl {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin desktop-xxl {
    @media (min-width: 1300px) {
        @content;
    }
}

// Printing media query
@mixin print-only {
    @media print {
        @content;
    }
}
